<template>
  <div class="search-section section-gap bg-primary">
    <div class="container">
    
     
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-8 mx-auto text-center subs-form">
						<h2>What We Can Help You?</h2>
						<p class="text-white mb-0">We are driven by creating experiences that deliver results for your business and for your consumers. Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
					</div>
				</div>
			</div>


      

      <div class="row">
        <div class="col-sm-12 col-md-7 mx-auto subs-form">
          <form class="d-block">
            <div class="d-flex justify-align-center">
              <input
                type="text"
                placeholder="Search ..."
                id="validationCustom01"
                formcontrolname="email"
                class="form-control subscribe-input form-control"
              />

              <button type="submit" class="btn btn-success d-flex align-items-center">
                <i class="ion ion-md-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>